<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>调拨</span>
            </div>

            <!-- 调拨-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToAllocate"
                            class="font1"
                            :disabled="!hasOpenAllocatePrivilege"
                        >
                            调拨申请
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForCreateAllocate"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可新建调拨申请
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToAllocate"
                            :disabled="!hasCreateAllocatePrivilege"
                            >①新建调拨申请</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择类型(双方都使用本系统选择内部调拨/单方选择自建调拨)-选择调入与调出机构
                            -点击选择商品基本信息-选择需要调拨的商品-确定-填写调拨价、调拨数量-点击保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToAllocate"
                            :disabled="!hasReviewAllocatePrivilege"
                            >②审核调拨申请</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的采购单-点击审核-审核成功后双方可自行进行出入库操作</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToAllocate"
                            :disabled="!hasReviewAllocatePrivilege"
                            >③调拨数量/调拨价不正确</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现数量/调拨价有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                            -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核调拨单操作(如果已发生出入库无法反审核)
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--调拨出库方-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToAllocateOut"
                            class="font1"
                            :disabled="!hasStockAllocateOutPrivilege"
                            >调拨出库方</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForOpenAllocateOut"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可进行调拨出库操作</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToAllocateOut"
                            :disabled="!hasStockAllocateOutPrivilege"
                            >①调拨出库</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>在需要出库的单据上点击出库-完成后库存自动扣除</span>
                    </el-col>
                </el-row>
            </el-card>

            <!--调拨入库方-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToAllocateIn"
                            class="font1"
                            :disabled="!hasStockAllocateInPrivilege"
                            >调拨入库方</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForOpenAllocateIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可进行调拨入库操作</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToAllocateIn"
                            :disabled="!hasStockAllocateInPrivilege"
                            >①调拨入库</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>在需要入库的单据上点击入库-完成后入库的商品可进行销售</span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const allocatePrivilegeFlag = 'menu.stock.allocate';
const openAllocatePrivilegeFlag = `${allocatePrivilegeFlag}.open`;
const createAllocatePrivilegeFlag = `${allocatePrivilegeFlag}.create`;
const reviewAllocatePrivilegeFlag = `${allocatePrivilegeFlag}.review`;

const allocateInPrivilegeFlag = 'menu.stock.allocateIn';
const openAllocateInPrivilegeFlag = `${allocateInPrivilegeFlag}.open`;
const stockAllocateInPrivilegeFlag = `${allocateInPrivilegeFlag}.stock`;

const allocateOutPrivilegeFlag = 'menu.stock.allocateOut';
const openAllocateOutPrivilegeFlag = `${allocateOutPrivilegeFlag}.open`;
const stockAllocateOutPrivilegeFlag = `${allocateOutPrivilegeFlag}.stock`;

export default {
    name: 'AllocateGoodsGuide',
    data() {
        return {
            rolesForCreateAllocate: [],
            rolesForOpenAllocateOut: [],
            rolesForStockAllocateOut: [],
            rolesForOpenAllocateIn: [],
            rolesForStockAllocateIn: [],
        };
    },
    created() {
        //查询能够"创建调拨申请"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createAllocatePrivilegeFlag).then((rst) => {
            this.rolesForCreateAllocate = rst;
        });
        //查询能够"打开调拨出库页面"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openAllocateOutPrivilegeFlag).then((rst) => {
            this.rolesForOpenAllocateOut = rst;
        });
        //查询能够"出库调拨"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockAllocateOutPrivilegeFlag).then((rst) => {
            this.rolesForStockAllocateOut = rst;
        });
        //查询能够"打开调拨入库页面"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openAllocateInPrivilegeFlag).then((rst) => {
            this.rolesForOpenAllocateIn = rst;
        });
        //查询能够"入库调拨"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockAllocateInPrivilegeFlag).then((rst) => {
            this.rolesForStockAllocateIn = rst;
        });
    },
    computed: {
        roleNamesForCreateAllocate() {
            return this.rolesForCreateAllocate.map((e) => e.name).join('，');
        },
        roleNamesForOpenAllocateOut() {
            return this.rolesForOpenAllocateOut.map((e) => e.name).join('，');
        },
        roleNamesForStockAllocateOut() {
            return this.rolesForStockAllocateOut.map((e) => e.name).join('，');
        },
        roleNamesForOpenAllocateIn() {
            return this.rolesForOpenAllocateIn.map((e) => e.name).join('，');
        },
        roleNamesForStockAllocateIn() {
            return this.rolesForStockAllocateIn.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToAllocate() {
            Util.nameJump(this, allocatePrivilegeFlag);
        },
        jumpToAllocateOut() {
            Util.nameJump(this, allocateOutPrivilegeFlag);
        },
        jumpToAllocateIn() {
            Util.nameJump(this, allocateInPrivilegeFlag);
        },
        hasOpenAllocatePrivilege() {
            return this.hasPrivilege(openAllocatePrivilegeFlag);
        },
        hasCreateAllocatePrivilege() {
            return this.hasPrivilege(createAllocatePrivilegeFlag);
        },
        hasReviewAllocatePrivilege() {
            return this.hasPrivilege(reviewAllocatePrivilegeFlag);
        },
        hasStockAllocateOutPrivilege() {
            return this.hasPrivilege(stockAllocateOutPrivilegeFlag);
        },
        hasStockAllocateInPrivilege() {
            return this.hasPrivilege(stockAllocateInPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
